import Box from "@mui/material/Box";
import classnames from "classnames";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import CustomCurrencyInput from "../../components/CustomCurrencyInput";
import React from "react";
import {useDealContext} from "../../contexts/DealContext";
import QuoteHelper from "../../utils/QuoteHelper";
import Switch from "@mui/material/Switch";
import {CustomSelect} from "../../components/CustomSelect";

export const DealPageAdditionalInfo = () => {
    const {
        dealState,
        setDealStateWithUpdate,
        updateDealResponse,
    } = useDealContext()

    const isLoading = updateDealResponse?.loading
    return (
        <Box
            className={classnames("dealPagePayment-column leftColumn")}
            style={{
                minWidth: '365px',
                height: '100%',
                marginLeft: '20px',
                marginTop: "98px",
                minHeight: "574px"
            }}
        >

            <Typography
                gutterBottom
                variant="h6"
                component="div"
                className={'header'}
            >
                Additional Inputs
            </Typography>

            <Divider className={'divider'}/>

            {/*<div*/}
            {/*    className={'payment-row'}*/}
            {/*>*/}
            {/*        <span*/}
            {/*            className={'payment-row_name'}*/}
            {/*        >*/}
            {/*            Security Deposit*/}
            {/*        </span>*/}
            {/*    <CustomCurrencyInput*/}
            {/*        className={classnames('payment-row_value', {*/}
            {/*            loading: isLoading*/}
            {/*        })}*/}
            {/*        value={dealState.security_deposit}*/}
            {/*        onChange={(value) => setDealStateWithUpdate({security_deposit: value})}*/}
            {/*    />*/}
            {/*</div>*/}

            <div
                className={'payment-row'}
            >
                    <span
                        className={'payment-row_name'}
                    >
                        Applicant Credit Score
                    </span>
                <CustomCurrencyInput
                    className={classnames('payment-row_value', {
                        loading: isLoading
                    })}
                    value={dealState.credit_score}
                    onChange={(value) => setDealStateWithUpdate({credit_score: value})}
                    InputProps={{}}
                />
            </div>
            {
                !dealState.is_purchase &&
                <React.Fragment>
                        <div className={'payment-row'}>
                            <span
                                className={'payment-row_name'}
                            >
                                One Pay
                            </span>
                            <Switch
                                label="Label"
                                disabled={isLoading}
                                checked={dealState.is_one_pay}
                                onChange={() => setDealStateWithUpdate({is_one_pay: !dealState.is_one_pay}, true)}
                            />
                        </div>
                        <div
                            className={'payment-row'}
                        >
                            <span
                                className={'payment-row_name'}
                            >
                                Security Deposits
                            </span>
                            <CustomSelect
                                className={classnames('payment-row_value select', {
                                    loading: isLoading
                                })}
                                value={dealState.number_of_security_deposits}
                                onChange={(event, newValue) => setDealStateWithUpdate({number_of_security_deposits: newValue}, true)}
                                handleHomeEndKeys
                                options={[0,1,2,3,4,5,6,7,8,9,10]}
                                getOptionLabel={(option) => String(option)}
                                forcePopupIcon
                                disableClearable
                                freeSolo={false}
                            />
                        </div>
                 </React.Fragment>
            }
        </Box>
    )
}